@import "../../styles/index.scss";

.main_table_delivery {
  max-height: calc(100vh - 345px);
  border-radius: 12px 12px 0px 0px;
  overflow-x: scroll;
  display: grid;
  grid-template-columns: 50px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 140px 1fr 1fr 1fr 1fr 1fr;
}

.main_table_delivery_active {
  display: flex;
  border-radius: 12px 12px 0px 0px;
  overflow-x: scroll;
  display: grid;
  grid-template-columns: 50px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 140px 1fr 1fr 1fr 1fr 1fr;
}

.main_table_branches {
  max-height: calc(100vh - 294px);
  border-radius: 12px 12px 0px 0px;
  overflow-x: scroll;
  display: grid;
  grid-template-columns: 50px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 140px 1fr 1fr 1fr 1fr 1fr;
}

.main_table_branches_active {
  display: flex;
  border-radius: 12px 12px 0px 0px;
  overflow-x: scroll;
  display: grid;
  grid-template-columns: 50px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 140px 1fr 1fr 1fr 1fr 1fr;
}

.main_table {
  position: relative;
  // max-height: 60vh;
  max-height: calc(100vh - 270px);
  margin-left: 66px;
  border-radius: 12px 0px 0px 0px;
  overflow-x: scroll;
  display: grid;
  transition: all 0.7s ease;

  grid-template-columns: 50px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 140px 1fr 1fr 1fr 1fr 1fr;

  &__empty {
    @include fontStyle(16px, 600, 22px, black);
    width: 70px;
  }

  &__orders {
    @include fontStyle(14px, 500, 22px, #6c727a);
    background-color: $color-white;
    padding: 20px 10px 20px 10px;
    border: 1px solid #f5f5f5;
    border-right: none;
    border-top: none;

    &_selectInput > div {
      @include fontStyle(12px, 400, 22px, #6c727a);
      width: 250px !important;
      border-radius: 8px !important;
    }

    &_courier {
      padding: 25px 9px 9px 9px;
      align-items: center;
      justify-content: center;
      background-color: $color-white;
      border: 1px solid $color-light-grey;
      border-right: none;
      border-top: none;
    }

    &_courier > div div {
      max-width: 120px;
    }

    &__inMyWay {
      @include fontStyle(12px, 600, 22px, #8781f7);
      background-color: #e0dff5;
      text-align: center;
      border-radius: 8px;
      min-width: 81px;
      padding: 6px 8px;
    }

    &_pending {
      @include fontStyle(12px, 600, 22px, #4ed2f7);
      background-color: #d5eef5;
      text-align: center;
      border-radius: 8px;
      min-width: 81px;
      padding: 6px 8px;
    }

    &_wherehouse {
      @include fontStyle(12px, 600, 22px, #4f96d9);
      background-color: #d5e3f0;
      text-align: center;
      border-radius: 8px;
      min-width: 81px;
      padding: 6px 8px;
    }

    &_deliverd {
      @include fontStyle(12px, 600, 22px, #3fcc75);
      background-color: #d2eddd;
      text-align: center;
      border-radius: 8px;
      min-width: 81px;
      padding: 6px 8px;
    }

    &_item {
      z-index: 9;
      background-color: #fff;
      display: flex;
      border: 1px solid #ebebeb;
      border-right: none;
      border-top: none;
      justify-content: center;
      align-items: center;

      &__eye {
        cursor: pointer;
        width: 24px;
        height: 24px;
        // z-index: 8;
      }
    }

    &_receive {
      @include fontStyle(12px, 600, 22px, #74bf4f);
      background-color: #dcfacb;
      border-radius: 8px !important;
      padding: 6px 8px;
      align-items: center;
      display: flex !important;
      justify-content: center;
      cursor: pointer;
    }
  }

  &__inputs {
    background-color: $color-white;
    padding: 20px 10px 20px 10px;
    border: 1px solid $color-light-grey;
    border-right: none;

    &_select {
      width: 307px;
    }
  }

  &__selectSearch {
    background-color: $color-white;
    padding: 20px 10px 20px 10px;
    border: 1px solid $color-light-grey;
    border-right: none;
  }

  &__selectSearch div {
    width: 100%;
    max-width: 250px;
  }

  &__selectSearch > div div {
    border-radius: 9px !important;
  }

  &__inputs:last-of-type {
    background-color: $color-white;
    border-right: 1px solid $color-light-grey;
  }

  &__head {
    @include fontStyle(14px, 700, 21px, #757272);
    position: sticky;
    top: 0;
    z-index: 20;
    background-color: $color-light-grey;
    padding: 8px 10px;

    &_empty {
      z-index: 20;
      position: sticky;
      top: 0;
      background-color: $color-light-grey;
      padding: 8px 0;
      text-align: center;
      width: 50px;
    }

    &_courier {
      @include fontStyle(14px, 700, 21px, #757272);
      background-color: $color-light-grey;
      padding: 8px 0;
      text-align: center;
      width: 329px;
    }

    &_row {
      &__places {
        @include fontStyle(12px, 400, 22px, black);
        width: 100%;
        padding: 13px 9px;
        height: 20px;
        border-radius: 8px;
        border: 1px solid #d9e1e7;
        min-width: 120px;
      }

      &__id {
        @include fontStyle(12px, 400, 22px, black);
        padding: 13px 0;
        height: 20px;
        border-radius: 8px;
        border: 1px solid #d9e1e7;
        width: 30px;
      }

      &__input {
        @include fontStyle(12px, 400, 22px, black);
        width: 100%;
        min-width: 200px;
        padding: 13px 9px;
        height: 20px;
        border-radius: 8px;
        border: 1px solid #d9e1e7;

        &_event {
          width: 308px;
        }

        &_courier {
          @include fontStyle(12px, 400, 22px, black);
          width: 110px;
          padding: 13px 32px 13px 9px;
          height: 20px;
          border-radius: 8px;
          border: 1px solid #d9e1e7;
          width: 250px;
        }

        &_id {
          @include fontStyle(12px, 400, 22px, black);
          width: 110px;
          padding: 13px 9px;
          height: 20px;
          border-radius: 8px;
          border: 1px solid #d9e1e7;
          width: 60px;
        }

        &_weigth {
          @include fontStyle(12px, 400, 22px, black);
          width: 110px;
          padding: 13px 9px;
          height: 20px;
          border-radius: 8px;
          border: 1px solid #d9e1e7;
          width: 100px;
        }
      }
    }
  }
}

.main_table_active {
  margin-left: 220px;
  border-radius: 12px 12px 0px 0px;
  overflow-x: scroll;
  display: grid;
  transition: all 0.7s ease;

  grid-template-columns: 50px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 140px 1fr 1fr 1fr 1fr 1fr;
}

.input_group_addon {
  position: absolute;
  text-align: center;
  width: 24px;
  white-space: nowrap;
  vertical-align: middle;
  display: table-cell;
  height: 27px;
  right: 8px;
}

.input_group {
  position: relative;
  display: flex;
}
