@import "../../styles/index.scss";

.main_table {
  display: grid;
  max-height: calc(100vh - 270px);
  grid-template-columns:
    minmax(50px, 100px) minmax(100px, 200px) minmax(100px, 200px) minmax(
      100px,
      212px
    )
    minmax(200px, 300px) minmax(200px, 300px) minmax(100px, 200px) minmax(100px, 300px);

  &__empty {
    @include fontStyle(16px, 600, 22px, black);
    width: 70px;
  }

  &__manifest {
    max-height: calc(100vh - 270px);

    border-radius: 12px 12px 0px 0px;
    display: grid;
    grid-template-columns:
      minmax(50px, 100px) minmax(100px, 100px) minmax(100px, 500px) minmax(
        100px,
        500px
      )
      minmax(100px, 500px) minmax(100px, 500px);
  }

  &__head {
    @include fontStyle(14px, 700, 21px, #757272);
    background-color: $color-light-grey;
    padding: 8px 10px;
  }

  &__head:first-child {
    padding: 8px 0;
  }

  &__icons {
    background-color: $color-white;
    padding: 20px 10px 20px 10px;
    
    border: 1px solid $color-light-grey;
    border-right: none;
    border-top: none;
    display: flex;
    justify-content: space-around;

    &_edit {
      width: 24px;
      height: 24px;
    }
  }

  &__img {
    position: absolute;
    left: 130px;
  }

  &__parcels {
    @include fontStyle(14px, 400, 22px, #6c727a);
    background-color: $color-white;
    padding: 20px 10px 20px 10px;
    border: 1px solid #f5f5f5;
    border-right: none;
    border-top: none;
  }

  // &__tbody {
  //   &_items {
  //     display: flex;
  //     justify-content: space-between;
  //     padding-left: 26px;
  //     align-items: center;

  //     &_item {
  //       max-width: 100px;
  //     }
  //   }
  // }
}
