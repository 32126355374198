@import "../../styles/index.scss";
.main_delivery__wrapper {
  padding: 0px 0 0 66px;
  transition: all 0.7s ease;
}
.main_delivery__wrapper_active {
  padding: 24px 0 0 221px;
  transition: all 0.7s ease;
}
.main_orders {
  background-color: $color-grey;
  &_item_distribute {
    @include fontStyle(14px, 600, 22px, white);
    background-color: #5dba2f;
    min-width: 100px;
    // padding: 9px;
    margin-right: 10px;
    margin-left: 10px;
    border-radius: 9px;
  }

  &__items {
    display: flex;
    justify-content: space-between;

    &_select {
      margin-right: 10px;
    }

    &_datePicker div {
      background-color: #eeeeee !important;
      border-radius: 8px;
    }

    &_sel div {
      width: 140px;
      background-color: #eeeeee !important;
      border-radius: 8px !important;
    }
  }
}
.main_orders_clicked {
  background-color: $color-grey;
  padding-left: 170px;
}
.pagination {
  padding: 66px 0 16px 0;
  display: flex;
  justify-content: flex-end;
}
.messages {
  margin-left: 42px;
}
.messages_active {
  margin-left: 200px;
}
