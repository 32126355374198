// @import "../styles/index.scss";

.container {
  width: 100%;
  max-width: 1877px;
  margin: 0 auto;
}

.main_wrapper {
  &__header {
    display: flex;
  }
}

// .item1 {
//   grid-area: header;
// }
// .item2 {
//   grid-area: menu;
// }
// .item3 {
//   grid-area: main;
// }

// .grid_container {
//   display: grid;
//   grid-template-areas:
//     "menu menu header header header header "
//     "menu menu main main main main "
//     "menu menu main main main main ";
//   gap: 10px;
//   // background-color: #2196f3;
//   padding: 10px;
// }
