a,
q,
s,
b,
u,
i,
dl,
dt,
dd,
ol,
ul,
li,
// tr,
// th,
// td,
// tt,
em,
h1,
h2,
h3,
h4,
h5,
h6,
div,
nav,
del,
pre,
big,
dfn,
img,
var,
ins,
kbd,
cite,
code,
menu,
body,
html,
span,
abbr,
samp,
ruby,
time,
mark,
form,
aside,
// tbody,
audio,
video,
embed,
// tfoot,
// thead,
label,
// table,
small,
strike,
strong,
legend,
canvas,
center,
applet,
figure,
footer,
header,
hgroup,
object,
output,
iframe,
article,
acronym,
address,
section,
caption,
details,
summary,
fieldset,
blockquote,
figcaption {
  display: block;
  font-size: 100%;
}
body {
  height: 100%;
  background-color: $color-grey;
}
table {
  border-spacing: 0;
  border-collapse: collapse;
}
input {
  box-shadow: none;
  -o-box-shadow: none;
  -ms-box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}
input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  margin: 0;
  -webkit-appearance: none;
}
button,
a {
  cursor: pointer;
  display: inline-block;
}
img {
  max-width: 100%;
}
* {
  border: 0;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Montserrat;
}
// p {
//   display: block;
//   margin-block-start: 1em;
//   margin-block-end: 1em;
//   margin-inline-start: 0px;
//   margin-inline-end: 0px;
// }
