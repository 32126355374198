
.table {
  padding-top: 10px;
  background: #EBEBEB;
  border-radius: 12px 12px 0 0;

  .ant-table-thead > tr > th {
    padding: 0 8px 10px 8px;
    background: #EBEBEB;
  }

  .ant-table-thead {
    .ant-table-bordered {
      border-right: none;
    }

    .ant-table-cell::before {
      display: none;
    }
  }
}


.t_title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 63px;
}

.title_text {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #757272;
  text-transform: capitalize;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  white-space: nowrap;
  margin-bottom: 10px;
}

.inp {
  border-radius: 8px;
  border: 1px solid #D9E1E7;

  &:hover, &:focus {
    border: 1px solid #5dba2f;
    outline: none;
  }
}

.edit {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-transform: capitalize;
  color: #5DBA2F;
  cursor: pointer;
}

.edit_disabled {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-transform: capitalize;
  color: #C1C1C1;
  cursor: pointer;
}

