@import "../../styles/index.scss";
.edit_btn {
  width: 20px;
  height: 20px;
  margin-left: 20px;
  margin-top: -5px;
}
.changeEventBtn {
  background: #5DBA2F;
  color: white;
  padding: 5px 10px;
  border-radius: 0.5rem;

}
.main_parcel {
  padding-top: 30px;
  width: 100%;
  background-color: $color-grey;

  &__wrapper {
    // margin-left: 79px;
    padding-left: 66px;
    transition: all 0.7s ease;

    &_head {
      &_items {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 0;
        padding-bottom: 30px;
      }

      &_button {
        @include fontStyle(14px, 600, 22px, $color-white);
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $onex-color;
        border-radius: 8px;
        padding: 6px 11px;
        &__plus {
          margin-right: 5px;
        }
      }
    }

    &_export {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: #757272;
      min-width: 102px;
      background-color: #eeeeee;
      border-radius: 8px;
      // @include fontStyle(14px, 600, 22px, $color-white);
      // max-height: 30px;
      // border-radius: 8px;
      // padding: 5px 11px;
      // margin-left: 10px;
      // background-color: $onex-color;
    }

    &_select {
      max-width: 100%;
      margin-right: 10px;
      border-radius: 6px;
      max-height: 40px;
      min-width: 150px;

      &_error {
        max-width: 100%;
        margin-right: 10px;
        border-radius: 6px;
        max-height: 40px;
        min-width: 150px;
        border: 1px solid red;
      }
    }

    &_date div {
      background-color: #eeeeee;
      border-radius: 8px;
    }

    &_selectName div {
      border-radius: 8px !important;
    }
  }

  &__wrapper_active {
    // margin-left: 234px;
    padding-left: 221px;
    transition: all 0.7s ease;
  }
}
.pagination {
  display: flex;
  justify-content: flex-end;
  padding: 66px 0 16px 0;
}
