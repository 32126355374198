@import "../../styles//index.scss";
.scan {
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 100px;
  input {
    padding: 10px;
    border: 1px solid #5DBA2F;
    border-radius: 1rem;
  }
}
.main_delivery {
  &__wrapper_active {
    padding: 24px 0 0 221px;
    transition: all 0.7s ease;
  }
  &__wrapper {
    padding: 30px 0 0 66px;
    transition: all 0.7s ease;

    &_items {
      display: flex;

      &_left {
        display: flex;
        width: 100%;
      }
    }

    &_select {
      @include fontStyle(14px, 500, 22px, #757272);
      min-width: 416px;
      &_error {
        @include fontStyle(14px, 500, 22px, #757272);
        min-width: 416px;
      }
      &_error > div {
        border: 1px solid red !important;
        border-radius: 8px 0px 0px 8px !important;
        border-right: none !important;
        padding: 10px 0;
      }
    }

    &_select > div {
      border-radius: 8px 0px 0px 8px !important;
      border-right: none !important;
      border: 1px solid #d9e1e7;
      padding: 10px 0;
    }

    &_button {
      background-color: $onex-color !important;
      border-radius: 0px 8px 8px 0px !important;

      &_exoprt {
        @include fontStyle(14px, 500, 22px, #757272);
        min-width: 102px !important;
        background-color: #eeeeee !important;
        border-radius: 8px !important;
        margin-left: 42px !important;
      }

      &_exoprt:focus {
        @include fontStyle(14px, 500, 22px, #757272);
        min-width: 102px !important;
        background-color: #eeeeee !important;
        border-radius: 8px !important;
        margin-left: 42px !important;
      }
      &_exoprt:hover {
        @include fontStyle(14px, 500, 22px, #757272);
        min-width: 102px !important;
        background-color: #eeeeee !important;
        border-radius: 8px !important;
        margin-left: 42px !important;
      }
    }

    &_button:hover {
      background-color: $onex-color !important;
    }

    &_button:active {
      background-color: $onex-color !important;
    }

    &_button:focus {
      background-color: $onex-color !important;
    }
    &_date {
      display: flex;
    }
    &_date > div {
      min-width: 243px !important;
      background-color: #eeee;
      border-radius: 8px;
    }

    &_buttons {
      display: flex;
      justify-content: space-between;
      padding: 15px 0 15px 0;
      &_save {
        margin-left: 15px;
        @include fontStyle(14px, 500, 22px, $color_white);

        background-color: $onex-color !important;
        border-radius: 8px !important;
        color: white !important;
      }
      &_save:focus {
        @include fontStyle(14px, 500, 22px, $color_white);

        background-color: $onex-color;
        border-radius: 8px;
      }
      &_save:hover {
        @include fontStyle(14px, 500, 22px, $color_white);

        background-color: $onex-color;
        border-radius: 8px;
      }

      &_import {
        @include fontStyle(14px, 500, 22px, $color_white);
        display: flex !important;
        margin-left: 32px !important;
        background-color: $onex-color !important;
        border-radius: 8px !important;
        color: white !important;
      }

      &_import:focus {
        @include fontStyle(14px, 500, 22px, $color_white);
        display: flex !important;
        margin-left: 32px !important;
        background-color: $onex-color !important;
        border-radius: 8px !important;
      }
      &_import:hover {
        @include fontStyle(14px, 500, 22px, $color_white);
        display: flex !important;
        margin-left: 32px !important;
        background-color: $onex-color !important;
        border-radius: 8px !important;
      }

      &_add {
        @include fontStyle(14px, 500, 22px, #757575);
        display: flex !important;
        background-color: transparent !important;
        border-radius: 8px !important;
        border: 1px solid $onex-color !important;
      }
      &_add:focus {
        @include fontStyle(14px, 500, 22px, #757575);
        display: flex !important;
        background-color: transparent !important;
        border-radius: 8px !important;
        border: 1px solid $onex-color !important;
      }

      &_add:hover {
        @include fontStyle(14px, 500, 22px, #757575);
        display: flex !important;
        background-color: transparent !important;
        border-radius: 8px !important;
        border: 1px solid $onex-color !important;
      }
      &_rigth {
        display: flex;
      }
    }
  }
}
.pagination {
  display: flex;
  justify-content: flex-end;
  padding: 66px 0 16px 0;
}
