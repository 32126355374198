@import "../../styles/index.scss";

.main_modal {
  height: 160vh;
  width: 100%;
  background-color: rgba(77, 77, 77, 0.25);
  position: absolute;
  z-index: 100;

  &__card {
    position: relative;

    margin: 41px auto;
    width: 1006px;
    background-color: $color-white;
    padding: 52px;
    // min-height: 800px;
    height: 817px;

    &__wrapper {
      &_items2 {
        padding: 0 0 52px 0;

        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        &_input {
          border: 1px solid #d9e1e7;
          padding: 5px;
          max-width: 195px;
          border-radius: 8px;
        }
      }
      &_items {
        padding: 53px 0 40px 0;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;

        &_label {
          font-size: 12px;
          color: red;
        }
  
        &_sl{
          border:1px solid #d9e1e7 ;
          max-width: 195px;
          border-radius: 8px;
          width: 100%;
          height: 37px !important;

        }
        &_sl  div   {
     
          width: 100%;

        }
        &_sl div div {
          border:none !important;
          background-color:transparent !important;
           box-shadow:none !important;


        }

        &_sl div div:hover {
          border-color: #d9e1e7 !important;
        }
        &_sl div div:active {
          border-color: #d9e1e7 !important;
          box-shadow:none !important;
        }

        &_sl div div:focus {
          border-color: #d9e1e7 !important;
          box-shadow: none !important;
        }

        &_input {
          border: 1px solid #d9e1e7;
          padding: 5px;
          max-width: 212px;
          border-radius: 8px;
        }

        &_label {
          font-size: 12px;
          color: red;
        }

        &_input > div {
          border: none;
        }
      }

      &_buttons {
        position: absolute;
        left: calc(50% - 200px);
        bottom: 52px;

        display: flex;
        justify-content: center;

        &_delivery {
          position: absolute;
          bottom: 52px;
          left: calc(50% - 87px);
          display: flex;
          justify-content: center;
        }
      }

      &_button:first-child {
        @include fontStyle(14px, 600, 22px, #000);
        width: 134px;
        margin-right: 34px;
        background-color: transparent;
        border: 2px solid #99b2c6;
        border-radius: 8px;
        padding: 7px;
      }
      &_button {
        @include fontStyle(14px, 600, 22px, $color-white);
        width: 134px;
        margin-right: 34px;
        background-color: $onex-color;
        border-radius: 8px;
        padding: 7px;
        max-height: 39px;

        &_sent {
          @include fontStyle(14px, 600, 22px, #fff);
          background: #5dba2f;
          padding: 0 10px;
          height: 36px;
          border-radius: 8px;
          margin-left: 10px;
        }
      }
      &_select {
        &_item {
          border: 1px solid #d9e1e7 !important;
          border-radius: 8px !important;
        }
        &_item > div > div {
          border: none;
          border-radius: 8px;
          padding: 8px 0;
        }

        &_item span {
          background: transparent !important;
          border: none !important;
        }
        &_item span span:first-child {
          border-radius: 8px 0 0 8px;
        }

        &_item span span:last-child {
          border-radius: 0 8px 8px 0;
        }
        &_item span span {
          background: #eee !important;
          border: none !important;
          margin: 0 !important;
          display: flex !important;
          justify-content: space-between !important;
          padding: 5px 5px;
          align-items: center;
        }
      }
      &_item > div:first-child div:first-child span {
        background-color: red;
      }

      &_form {
        padding: 53px 0 40px 0;
        display: grid;
        grid-template-columns: 195px 195px 195px;
        gap: 0 24px;
      }

      &_list {
        width: 100%;
        border-radius: 8px;
        padding: 26px;
        border: 1px solid #d9e1e7;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        text-align: center;
        gap: 25px 18px;

        &_item {
          display: flex;
          align-items: center;
          justify-content: center;
          background: #eeeeee;
          border-radius: 8px;
          padding: 5px 0;
          min-width: 270px;
          width: 175px;
          padding: 0 8px;
          &_empty {
            @include fontStyle(16px, 600, 22px, black);
            display: flex;
          }
        }

        &_item p {
          margin-right: 12px;
        }
      }

      &__select > div {
        width: 423px !important;
        border-radius: 8px !important;
      }

      &_path {
        @include fontStyle(16px, 600, 22px, #000);
        padding-bottom: 34px;
      }
    }
  }
}
label {
  @include fontStyle(12px, 500, 22px, black);
}
