@import "../../styles/index.scss";

.main_option {
  position: fixed;
  top: 0;
  z-index: 21;
  right: -110px;
  display: flex;
  justify-content: flex-end;
  transform: translateX(90%);
  transition: transform 1s;

  &_open {
    position: fixed;
    top: 0;
    z-index: 21;
    right: 0px;
    display: flex;
    justify-content: flex-end;
    transform: translateX(0%);
    transition: transform 1s;
  }

  &_wrapper {
    padding: 0 0 25px;
    width: 1117px;

    &_cards {
      background-color: $color-grey;
      padding-bottom: 100px;
      box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.05);
      border-radius: 8px;
      height: 1118px;

      &__items {
        display: flex;
        background-color: $color-white;
        margin: 26px;
        box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        padding: 13px 30px;
        &_events {
          @include fontStyle(14px, 600, 22px, #757272);
        }

        &_item {
          display: flex;
          justify-content: space-between;
        }
      }

      &__items:last-child {
        background-color: #e9f1e6 !important;
      }
    }
    &__first {
      display: flex;
      padding-left: 15px;
      width: 401px;
      border-right: 1px solid #e1e1e1;

      &_names {
        @include fontStyle(14px, 600, 22px, #757272);
        width: 169px;
        border: 1px solid transparent;
        padding-right: 21px;
      }

      &_names div {
        padding-bottom: 8px;
      }

      &_details {
        @include fontStyle(14px, 400, 22px, #0b0a0a);
        min-width: 106px;
        border: 1px solid transparent;
        padding-right: 21px;
      }

      &_details div {
        padding-bottom: 8px;
      }

      &_product {
        display: flex;
        padding-left: 15px;
        width: 780px;
      }
    }

    &__second {
      display: flex;
      padding-left: 11px;
      width: 313px;
      border: 1px solid transparent;

      &_names {
        @include fontStyle(14px, 600, 22px, #757272);
        min-width: 199px;
        border: 1px solid transparent;
        padding-right: 21px;
      }
      &_names div {
        padding-bottom: 8px;
      }

      &_details {
        @include fontStyle(14px, 400, 22px, #0b0a0a);
        min-width: 181px;
        border: 1px solid transparent;
        padding-right: 21px;
      }

      &_details div {
        padding-bottom: 8px;
      }
    }

    &__nav {
      display: flex;
      padding: 22px 26px;
      z-index: 12;

      &_item:first-child {
        margin-right: 20px;
        cursor: pointer;
      }

      &_item {
        text-transform: capitalize;
        &_close {
          margin-right: 20px;
          transform: rotate(180deg);
          cursor: pointer;

        }
      }
    }

    &__title {
      @include fontStyle(18px, 600, 22px, #505050);
      width: 222px;
      border-right: 1px solid #e1e1e1;
    }
  }
}
.opasity {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  background-color: rgba(77, 77, 77, 0.25);
  transition: background-color 1s linear;

  &_show {
    transition: background-color 1s linear;
  }
}
