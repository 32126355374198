@import "../../styles/index.scss";

.main_branches {
  &__wrapper_active {
    padding: 30px 0 0 221px;
    transition: all 0.7s ease;
  }
  &__wrapper {
    padding: 30px 0 0 66px;
    transition: all 0.7s ease;

    &_items {
      display: flex;
      padding-bottom: 30px;

      &_left {
        display: flex;
        width: 100%;
      }
    }

    &_sel div {
      width: 140px;
      background-color: #eeeeee !important;
      border-radius: 8px !important;
    }

    &_select {
      @include fontStyle(14px, 500, 22px, #757272);
      min-width: 195px;
      border-radius: 8px 0px 0px 8px !important;
      border-right: none !important;
      border: 1px solid #d9e1e7;
      padding: 0 8px;
    }

    &_select > div {
    }

    &_button {
      background-color: $onex-color;
      border-radius: 0px 8px 8px 0px;

      &_exoprt {
        @include fontStyle(14px, 500, 22px, #757272);
        min-width: 102px;
        background-color: #eeeeee;
        border-radius: 8px;
        margin-left: 42px;
      }
      
      &_distribute {
        @include fontStyle(14px, 600, 22px, white);
        background-color: #5dba2f;
        min-width: 100px;
        margin-right: 10px;
        margin-left: 10px;
        border-radius: 9px;
      }
    }

    &_button:hover {
      background-color: $onex-color !important;
    }

    &_button:focus {
      background-color: $onex-color !important;
    }

    &_date {
      display: flex;
    }

    &_date > div {
      width: 270px;
      border-radius: 8px;
      background-color: #eeee;
    }
  }
}
.pagination {
  padding: 66px 0 16px 0;
  display: flex;
  justify-content: flex-end;
}
