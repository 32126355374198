@import "../../styles/index.scss";

.login_input {
  border: 1px solid;
  border-radius: 6px;
  padding: 10px;
  margin-bottom: 20px;
}

.login_wrapper {
  margin: 60px 40px;
}

.login_button {
  background-color: #5dba2f;
  width: 100%;
  padding: 6px;
  color: white;
  border-radius: 6px;
}
