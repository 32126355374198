@import "../../styles/index.scss";

.main_manifest {
  padding-left: 66px;
  transition: all 0.7s ease;

  padding-top: 30px;
  width: 100%;
  background-color: $color-grey;
}
.main_manifest_active {
  padding-left: 221px;
  transition: all 0.7s ease;

  padding-top: 30px;
  width: 100%;
  background-color: $color-grey;
}
