@import "../../styles/index.scss";

.main_slider {
  position: relative;
  position: fixed;
  padding: 15px 0 11px 4px;
  background-color: $color-white;
  z-index: 9;

  height: 100vh;
  width: 53px;
  transition: width 0.7s;

  &__logo {
    margin-bottom: 70px;
    padding-bottom: 18px;
    border-bottom: 1px solid rgba(217, 219, 222, 0.3);
  }

  &__logo_open {
    margin-bottom: 70px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(217, 219, 222, 0.3);
  }

  &__icons {
    &_item {
      &_image {
        padding: 10px;
        z-index: 1000;
      }

      &_text {
        z-index: 0;
        width: 0px;
        opacity: 0;
        @include fontStyle(14px, 600, 24px, #848b95);
        transition: opacity 0.4s;

        &_clicked {
          @include fontStyle(14px, 600, 24px, #848b95);
          opacity: 6;
          transition: opacity 1.5s;
        }

        &_parcel {
          @include fontStyle(14px, 600, 24px, #848b95);
        }
      }
    }
    &_item__active {
      background-color: rgba(166, 166, 166, 0.16);
      border-left: 3px solid $onex-color;
      display: flex;
      align-items: center;
    }
  }

  &__openImage {
    top: 500px;
    position: fixed;
    left: 30px;
    transform: translateX(13%);
    transition: left 0.7s;
  }
}

.main_sliderOpen {
  position: relative;
  position: fixed;
  padding: 15px 0 11px 4px;
  background-color: $color-white;
  height: 100vh;
  width: 208px;
  transition: width 0.7s;

  &__logoOpen {
    margin-bottom: 70px;
  }

  &__iconsOpen {
    &_itemOpen {
      cursor: pointer;
      display: flex;
      align-items: center;
      border-left: 3px solid white;

      &__option:hover {
        @include fontStyle(14px, 600, 24px, #848b95);
        display: flex;
        justify-content: center;
      }

      &__option {
        @include fontStyle(14px, 600, 24px, #848b95);
        display: flex;
        justify-content: center;

        &_active {
          @include fontStyle(14px, 600, 24px, #848b95);
          display: flex;
          justify-content: center;
          background-color: rgba(166, 166, 166, 0.16);
        }
        &_active:hover {
          @include fontStyle(14px, 600, 24px, #848b95);
          display: flex;
          justify-content: center;
          background-color: rgba(166, 166, 166, 0.16);
        }

        &_dot {
          margin-right: 10px;
        }

        &_none {
          display: none;
        }
      }
      &_imageOpen {
        padding: 10px;
        z-index: 1000;
      }

      &__select {
        width: 0;
        opacity: 0;
        display: flex;
        justify-content: space-between;
        transition: opacity 0.4s;

        &_image {
          transition: all 0.5s;
          margin-left: 67px;
        }

        &_image_rotate {
          transition: all 0.5s;
          margin-left: 67px;

          transform: rotate(180deg);
        }
      }

      &__select_checked {
        opacity: 6;
        transition: opacity 1.5s;
        display: flex;
        justify-content: space-between;
      }
    }
    &_itemOpen__activeOpen {
      display: flex;
      align-items: center;

      background-color: rgba(166, 166, 166, 0.16);
      border-left: 3px solid $onex-color;
    }
  }

  &__openImageOpen {
    position: fixed;
    left: 185px;
    top: 500px;

    transform: rotate(180deg);
    transition: left 0.7s;
  }
}
