@import "../../styles/index.scss";

.main_header {
  &__wrapper {
    background-color: $color-white;
    padding-left: 49px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-height: 63px;
    padding: 15px 26px 15px 80px;

    &_image {
      margin-right: 10px;
    }
    &_title {
      @include fontStyle(18px, 600, 27px, #000);
    }

    &_info {
      margin-left: 15px;

      &_select {
        display: flex;
        align-items: center;

        &_logo {
          @include fontStyle(14px, 600, 22px, $color-white);
          border: 1px solid #5dba2f;
          padding: 9px;
          width: 43px;
          height: 43px;
          text-align: center;
          background-color: #5dba2f;
          border-radius: 55px;
          margin-right: 10px;
        }
      }
      &_option {
        display: none;

        &_active {
          padding: 10px;
          display: block;
          position: absolute;
          top: 64px;
          right: 10px;
          z-index: 200;
          background-color: $color-white;
          box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
          border-radius: 8px;

          &_line {
            border-bottom: 1px solid #f5f5f5;
          }

          &_wrap {
            margin-top: 12px;
            cursor: pointer;
            display: flex;

            &_item {
              @include fontStyle(16px, 600, 24px, #848b95);
              margin-left: 7px;
            }
          }

          &_wrap:hover {
            cursor: pointer;

            background-color: #e6f7ff;
            display: flex;
          }
          &_item {
            @include fontStyle(14px, 600, 22px, #6d6d6d);
            margin-bottom: 12px;

            cursor: pointer;
            padding: 6px;
          }

          &_item:hover {
            cursor: pointer;
            background-color: #e6f7ff;
          }
        }
      }
    }
  }
  &__wrapper_active {
    background-color: $color-white;
    padding-left: 49px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-height: 63px;
    padding: 15px 26px 15px 245px;
  }
}
