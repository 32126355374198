@media (max-width: 1880px) {
  .container {
    // width: 100%;
    // max-width: 1830px;
    // margin: 0 auto;
  }
  .main_table_mobile {
    margin-left: 79px !important;
  }

  .main_table_mobile_active {
    margin-left: 235px !important;
  }

  .main_parcel_mobile {
    padding-left: 77px !important;
    padding-right: 26px !important;
  }

  .main_parcel_mobile_active {
    padding-left: 232px !important;
    padding-right: 26px !important;
  }

  .main_delivry_mobile {
    padding: 0 0 0 78px !important;
  }

  .main_delivry_mobile_active {
    padding: 24px 0 0 232px !important;
  }
  .main_branches_mobile_active {
    padding: 30px 0 0 234px !important;
  }
  .main_branches_mobile {
    padding: 29px 0 0 79px !important;
  }
  .main_option_open {
    // left: 327px !important;
  }
}
// @media (max-width: 1500px) {
//   .main_option_open {
//     left: 327px !important;
//   }
// }
