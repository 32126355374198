@import "../../styles/index.scss";
.show_title {
  margin: 0 68px;
}
.pagination_arrow_left {
  background: $color_white;
  border: 1px solid $color-light-grey;
  box-sizing: border-box;
  border-radius: 2px;
}
.pagination_arrow_rigth {
  transform: rotate(180deg);
  border: 1px solid $color-light-grey;
  box-sizing: border-box;
  border-radius: 2px;
}

li:focus {
  border-color: none !important;
  background-color: $onex-color !important;
}

li:hover {
  @include fontStyle(14px, 500, "", white);

  // border-color: $onex-color !important;
  background-color: $onex-color !important;
  color: white !important;
}
li:active {
  border-color: $onex-color !important;
  background-color: $onex-color !important;
  color: white !important;
}
li:focus > a {
  color: $color_white;
  color: white !important;
}
.pagination_info {
  min-width: 85px;
  padding: 5px 10px;
  background-color: $color_white;
  border-radius: 6px;

  @include fontStyle(14px, 500, 22px, #778692);
}
p {
  margin-bottom: 0;
}

.pagination__wrapper ul li {
  @include fontStyle(14px, 500, "", #8d979c);
  border: none !important;
  border-radius: 8px !important;
}
.pagination__wrapper ul li button {
  @include fontStyle(14px, 500, "", #8d979c);
  border: none !important;
  border-radius: 8px !important;
}
.pagination__wrapper ul li {
  @include fontStyle(14px, 500, "", white);
  border: none !important;
  border-radius: 8px !important;
}

// .pagination__wrapper ul li {
//   color: #1890ff;
//   background: #5dba2f;
//   border-radius: 8px;
//   color: #fff;
// }
